import React from 'react';
import PropTypes from 'prop-types';
import { promoShape } from '../promo/shapes';
import Image from '../generic/Image';

if (process.env.CLIENT) {
  require('./Hero.scss'); // eslint-disable-line global-require
}

const Hero = ({ promo }) => (
  <a href={promo.url} className="home-page-hero__container">
    <Image
      className="home-page-hero__image"
      isLazy={false}
      src={promo.imageId}
      style={promo.coord ? { marginTop: `${promo.coord}px` } : {}}
    />
    <div className="home-page-hero__wrap">
      <div className="gel-wrap">
        <div className="gel-layout home-page-hero__layout">
          <div className="gel-layout__item gel-1/1 gel-5/8@l gel-7/12@xxl home-page-hero">
            <div className="home-page-hero__text">
              <h2 className="gel-trafalgar-bold home-page-hero__title">{promo.title}</h2>
              <p className="gel-body-copy home-page-hero__synopsis">{promo.synopsis}</p>
              {promo.type && <span className="home-page-hero__type gel-minion">{promo.type}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
);

Hero.propTypes = PropTypes.shape(promoShape).isRequired;

export default Hero;
