import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Collection from '../Collection';
import Promo from '../Promo';
import { promoShape } from '../shapes';

if (process.env.CLIENT) {
  require('./HomeSecondaryCollection.scss'); // eslint-disable-line global-require
}

const getBreakpoints = index =>
  index === 0 ? 'gel-1/1 gel-7/12@xxl' : 'gel-1/1 gel-1/2@m gel-5/24@xxl';

const getPromoClasses = index =>
  index === 0
    ? {
        content: 'gel-1/2 gel-1/3@m gel-3/8@l gel-5/14@xxl',
        image: 'gel-1/2 gel-2/3@m gel-5/8@l gel-9/14@xxl',
        promo: 'hero',
      }
    : {
        content: 'gel-1/2 gel-1/1@m gel-5/12@l gel-1/1@xxl',
        image: 'gel-1/2 gel-1/1@m gel-7/12@l gel-1/1@xxl',
      };

const HomeSecondaryCollection = ({
  id,
  title,
  description,
  maxCollectionSize,
  promos,
  TitleElement,
}) => (
  <Collection
    collectionClassName="home-secondary"
    description={description}
    id={id}
    TitleElement={TitleElement}
    title={title}
  >
    {promos
      .slice(0, maxCollectionSize)
      .map(({ id: promoId, imageId, title: promoTitle, synopsis, subtitle, type, url }, i) => (
        <div key={promoId} className={classNames('gel-layout__item', getBreakpoints(i))}>
          <Promo
            extraClasses={getPromoClasses(i)}
            collectionId={id}
            collectionTitle={title}
            id={promoId}
            imageId={imageId}
            title={promoTitle}
            titleClassName={i === 0 ? 'gel-double-pica-bold' : undefined}
            synopsis={synopsis}
            subtitle={subtitle}
            type={type}
            url={url}
          />
        </div>
      ))}
  </Collection>
);

HomeSecondaryCollection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  maxCollectionSize: PropTypes.number,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  TitleElement: PropTypes.node,
};

HomeSecondaryCollection.defaultProps = {
  description: '',
  maxCollectionSize: 3,
  title: undefined,
  TitleElement: 'h2',
};

export default HomeSecondaryCollection;
