import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import Hero from './Hero';
import SeeAllRecipesLink from '../generic/SeeAllRecipesLink';
import HomeSecondaryCollection from '../promo/collections/HomeSecondaryCollection';
import StandardCollection from '../promo/collections/StandardCollection';
import StandardCollection6Promos from '../promo/collections/StandardCollection6Promos';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import SeasonalPromoBanner from '../generic/seasonalPromo/SeasonalPromoBanner';
import { promoShape } from '../promo/shapes';
import { seasonalPromoShape } from '../generic/seasonalPromo/shapes';
import ContentSeparator from '../generic/ContentSeparator';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

const has = thing => thing && thing.length > 0;

export const Page = ({
  inSeasonData,
  meetTheChefs,
  promos,
  recipesFromProgrammes,
  specialDiets,
  techniques,
  techniquesTotalCount,
  topRecipes,
  articles,
  articlesTotalCount,
  seasonalPromo,
  foodImagesPath,
}) => (
  <>
    <MetaProperties
      title="Recipes and inspiration from your favourite BBC programmes and chefs"
      description="Find recipes from your favourite BBC programmes and chefs, or browse by ingredient or dish. With over 13000 recipes you're sure to find the perfect dish."
    />

    {has(promos) && (
      <>
        <Hero promo={promos[0]} />
        <div className="food-body__grey-background homepage__secondary-collection">
          <HomeSecondaryCollection
            containerClassName="homepage__secondary-collection"
            id="homepage-secondary-collection"
            promos={promos.slice(1)}
            maxCollectionSize={3}
          />
        </div>
      </>
    )}

    {has(articles) && (
      <>
        <div className="homepage__articles-section" id="homepage-articles--above">
          <StandardCollection12Promos
            breakpoints="gel-1/2 gel-1/4@l"
            title="Top stories"
            id="homepage-articles--above"
            promos={articles}
            maxCollectionSize={4}
          />
          <div className="articles__wrap gel-wrap">
            <SeeAllRecipesLink
              body="all stories"
              totalCount={articlesTotalCount}
              href="/food/articles"
            />
          </div>
        </div>
        <ContentSeparator />
      </>
    )}

    {has(topRecipes) && (
      <div className="homepage-collection__padding-bottom">
        <StandardCollection
          containerClassName="homepage__editor-picks"
          id="homepage-top-recipes-collections"
          title={"Try today's top recipes"}
          promos={topRecipes}
          maxCollectionSize={4}
        />
      </div>
    )}

    {has(recipesFromProgrammes) && (
      <>
        <StandardCollection6Promos
          id="homepage-recipes-from-programmes"
          title="Recipes from programmes"
          promos={recipesFromProgrammes}
          maxCollectionSize={6}
        />
        <ContentSeparator />
      </>
    )}

    {inSeasonData && (
      <div className="homepage__see-all-link-section">
        <StandardCollection12Promos
          breakpoints="gel-1/2 gel-1/4@l"
          id="homepage-in-season-recipes"
          title={`In season: ${upperFirst(inSeasonData.title)}`}
          promos={inSeasonData.recipes}
          maxCollectionSize={4}
        />
        <div className="see-all-recipes__wrap gel-wrap">
          <SeeAllRecipesLink
            body={`all ${inSeasonData.title} recipes`}
            totalCount={inSeasonData.totalCount}
            href={`/food/${inSeasonData.id}`}
          />
        </div>
      </div>
    )}

    {has(meetTheChefs) && (
      <div className="food-body__grey-background homepage-collection__padding-bottom">
        <StandardCollection
          breakpoints="gel-1/2 gel-1/4@l"
          id="homepage-meet-the-chefs"
          title="Meet the chefs"
          promos={meetTheChefs}
          maxCollectionSize={4}
        />
        <ContentSeparator />
      </div>
    )}

    {has(specialDiets) && (
      <div className="homepage__see-all-link-section">
        <StandardCollection6Promos
          id="homepage-special-diets"
          title="Get nutritional advice and recipes"
          promos={specialDiets}
          maxCollectionSize={6}
        />
        <div className="see-all-recipes__wrap gel-wrap">
          <SeeAllRecipesLink
            body="all diets"
            className="homepage-special-diets__see-all-link"
            href="/food/diets"
          />
        </div>
      </div>
    )}

    {has(techniques) && (
      <div className="homepage-techniques homepage__see-all-link-section">
        <StandardCollection12Promos
          breakpoints="gel-1/2 gel-1/4@l"
          id="homepage-techniques"
          title="Learn how to cook"
          promos={techniques}
          maxCollectionSize={4}
        />
        <div className="see-all-recipes__wrap gel-wrap">
          <SeeAllRecipesLink
            body="all cooking techniques"
            totalCount={techniquesTotalCount}
            href="/food/techniques"
          />
        </div>
      </div>
    )}

    {seasonalPromo && (
      <SeasonalPromoBanner season={seasonalPromo} foodImagesPath={foodImagesPath} />
    )}
  </>
);

Page.defaultProps = {
  inSeasonData: undefined,
  techniquesTotalCount: 0,
  seasonalPromo: undefined,
};

Page.propTypes = {
  inSeasonData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
    title: PropTypes.string.isRequired,
    totalCount: PropTypes.number,
  }),
  meetTheChefs: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  recipesFromProgrammes: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  specialDiets: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  promos: PropTypes.arrayOf(
    PropTypes.shape({
      ...promoShape,
      coord: PropTypes.number.isRequired,
    })
  ).isRequired,
  techniques: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  techniquesTotalCount: PropTypes.number,
  topRecipes: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  articlesTotalCount: PropTypes.number.isRequired,
  seasonalPromo: PropTypes.shape(seasonalPromoShape),
  foodImagesPath: PropTypes.string.isRequired,
};

export default connect(
  ({
    homeReducer: {
      inSeasonData,
      meetTheChefs,
      promos,
      recipesFromProgrammes,
      specialDiets,
      techniques,
      techniquesTotalCount,
      topRecipes,
      articles,
      articlesTotalCount,
    },
    pageReducer: { seasonalPromo, foodImagesPath },
  }) => ({
    inSeasonData,
    meetTheChefs,
    promos,
    recipesFromProgrammes,
    specialDiets,
    techniques,
    techniquesTotalCount,
    topRecipes,
    articles,
    articlesTotalCount,
    seasonalPromo,
    foodImagesPath,
  })
)(Page);
